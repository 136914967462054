


























import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'

@Component
export default class TextField extends AbstractField {
  onClearClick(): void {
    this.field.setValue('')
  }
}
